<!--
Component: Navbar
* @FileDescription: 导航条
* @Author: ruixiaozi
* @Email: admin@ruixiaozi.com
* @Date: 2021年05月06日 00:05:45
* @Version: 1.0.0
-->
<template>
  <div class="navbar">
    <div class="left">
      <div class="logo">
        <img src="@/assets/logo.png" alt="" />
      </div>
      
      <div class="title">
        <div>渝荣科技</div>
        <div>yurong.design</div>
      </div>
    </div>
    <div class="right">
      <ul>
        <li v-for="(item,index) in menus" :key="index" 
            :class="{active:currentMenu.path==item.path}">
          <a :href="baseUrl+item.path" @click.prevent="handleItemClick(item)">{{item.name}}</a>
        </li>
        <!-- <li>
          <div class="login-block" :class="[isHome?'home':'other']">
            <a href="">登录</a>/<a href="">注册</a>
          </div>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  // Component name
  name: 'Navbar',
  // Component props
  props: {
    isHome:{
      type:Boolean,
      default:true
    }
  },
  // Locally registered components
  components: {
    
  },
  // Component status
  data () {
    return {
      menus:this.$router.options.routes,
      baseUrl:this.$router.options.base.substr(0,this.$router.options.base.length-1),
      
    }
  },
  // Calculate attribute
  computed: {
    currentMenu(){
      return this.$route;
    }
  },
  // Component watch
  watch: {

  },
  // Component methods
  methods: {
    handleItemClick(item){
      this.$router.push(item.path)
    },
    absolutePath(item){
      return 
    }
  },
  // Lifecycle hooks
  mounted(){
    console.log(this.$router);
  }
};
</script>

<style lang="scss" scoped>
.navbar{
  position: relative;
  width: 100%;
  height: 100px;
  overflow: visible;
  display: flex;
  padding: 20px 5%;
 

  .left{
    width: 300px;
    height: 100%;
    
    display: flex;

    
    .logo,.title{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      
    }
    .logo{
      width: 65px;
      
      margin: 0px 10px;
    
      img{
        width: 100%;
      }
    } 

    .title{
      flex: 1;

      div{
        color: #1FB8FF;
        font-size: 18px;
        font-weight: 700;
      }

      div:first-child{
        font-size: 32px;
        
      }
    }
  }

  .right{
    flex: 1;
    height: 100%;
    
    text-align: right;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //background-color: red;
    ul{
      list-style: none;
      overflow:visible;
      white-space:nowrap;
      
      padding: 0;
      margin: 0;
      li{
        display: inline-block;
        vertical-align: middle;
        margin: 0px 20px;
        position: relative;
        color: #1FB8FF;
        &.active::after{
          position: absolute;
          bottom: -15px;
          left: 10%;
          display: inline-block;
          content: "";
          width: 80%;
          
          background-color:#F69E38 ;
          height: 3px;
        }
        .login-block{
          display: inline-block;
          background-color: #1FB8FF;
          padding: 10px 30px;
          border-radius: 50px;
          &.other{
            color: #fff;
            a{
              color: #fff;
            }
          }
          color: #fff;
          font-size: 18px;
          a{
            color: #fff;
            font-size: 18px;
          }
        }
        a{
          display: inline-block;
          font-weight: 700;
          text-decoration: none;
          color: #1FB8FF;
          font-size: 24px;
        }
      }
    }
  }
}
</style>
