<!--
View: Home
* @FileDescription: 首页
* @Author: ruixiaozi
* @Email: admin@ruixiaozi.com
* @Date: 2021年05月05日 23:55:01
* @Version: 1.0.0
-->
<template>
  <div class="home-page">
    <div class="company-content">
      <p>渝荣节能科技成立于2016年，主要从事叶轮机设计、振动和噪音的测试与优化，以及节能环保产品的研发与成果转化。为各企业、科研院所和高校提供研发服务和技术支持。公司近几年完成了40多个项目，均获一致好评。
        
      </p>
      <p>
        公司拥有自主开发的叶轮机气动设计软件。该软件包含轴流压气机、轴流涡轮、离心压气机和向心涡轮等多个模块，在航空航天、涡轮增压器和工业领域都得到过大量应用和验证。软件包含一维设计，S2设计、三维详细设计等。气体模型包含理想气体、多组分真实气体以及气液两相模型。
      </p>
    </div>
    <div class="carousel-container">
      <el-carousel :interval="4000" type="card" height="500px">
        <el-carousel-item v-for="index in 4" :key="index">
          <div class="img" :style="{
            backgroundImage: `url('/home/${index}.jpg')`
          }">
            
          </div>
        </el-carousel-item>
        
      </el-carousel>
      
    </div>
    
  </div>
</template>

<script>

export default {
  // View name
  name: 'Home',
  // View props
  props: {
    
  },
  // Locally registered components
  components: {
    
  },
  // View status
  data () {
    return {
    }
  },
  // Calculate attribute
  computed: {
    
  },
  // View watch
  watch: {

  },
  // View methods
  methods: {

  },
  // Lifecycle hooks
  // 路由切换，重新创建组件，（在这里获取数据）
  beforeRouteEnter (to, from, next) {

    next();
  },
  // 路由参数变化（或者重复点击当前路由导致参数变化），（在这里获取数据）
  beforeRouteUpdate(to, from, next){

    next();
  },
};
</script>

<style lang="scss" scoped>
.home-page{
  flex: 1;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto  600px;
  row-gap: 50px;
  padding-top: 50px;
  .carousel-container{
    overflow: hidden;
    justify-self: center;
    width: 1400px;
    height: 100%;
    div {
      height: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    /* .el-carousel__item:nth-child(2n) {
      background-color: #1FB8FF;
    }
    
    .el-carousel__item:nth-child(2n+1) {
      background-color: #1FB8FF;
    } */

    .img {
      padding: 8px;
      border: 8px solid #fff;
      border-radius: 16px;
    }
  }

  .company-content {
    height: fit-content;
    padding: 16px;
    background-color: #1fb8ff;
    color: #fff;
    padding: 16px 20%;
    line-height: 32px;
    font-size: 20px;
    text-indent: 2em;
  }

  p {
    margin: 0;
    padding: 0;
  }

  
}
</style>

