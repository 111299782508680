import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace (location) {
  return originalReplace.call(this, location).catch(err => err)
}

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '首页',
    component: Home
  },
  /* {
    path: '/about',
    name: '软件',
    component: () => import('../views/About.vue')
  }, */
  {
    path: '/PerformanceIntroduction',
    name: '公司业务',
    component: () => import('../views/PerformanceIntroduction.vue')
  },
  {
    path: '/Software',
    name: '软件',
    component: () => import('../views/Software.vue')
  },
  {
    path: '/DevelopmentServices',
    name: '研发服务',
    component: () => import('../views/DevelopmentServices.vue')
  },
  /* {
    path: '/Test',
    name: '测试',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/Test.vue')
  } */
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
