<template>
  <div id="app">
    <div id="container" :class="[backgroundClass]">
      <navbar :isHome="isHome"></navbar>
      <router-view />
    </div>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      isHome: true,
    };
  },
  computed: {
    backgroundClass() {
      return this.isHome ? "home" : "other";
    },
  },
  created() {
    this.isHome = location.pathname == this.$router.options.base;
    this.$router.beforeEach((to, from, next) => {
      this.isHome = to.path == "/";
      next();
    });
  },
  mounted() {},
};
</script>

<style lang="scss">
*{
  box-sizing: border-box;
}
#container{
  width: 100%;
  min-height: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  &.home::after{
    opacity: 1;
  }
  &.other::after{
    opacity: 0;
  }
  &::after {
    content: "";
    background-image: url('~@/assets/bg.png');
    background-size: cover;
    background-position: center;
    transition: all 0.3s;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;   
  }
  
  
  
  
}

</style>
